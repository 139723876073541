
.top_header {
    box-shadow: 0 0 5px #d4d4d4;
}

.header-widget .q_font_awsome_icon {
    vertical-align: 0 !important;
}

.side_menu_button .search_covers_header {
    height: auto;
}

.fixed_top_header .header_bottom_right_widget_holder {
    padding: 0px 10px;
}

.fixed_top_header .side_menu_button {
    border-right: 1px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
    padding: 0px 6px;
}

.qode_search_form_3 .container_inner .qode_search_close {
    right: 11px;
}

header:not(.with_hover_bg_color) nav.main_menu > ul > li:hover > a {
    opacity: 1;
}

.blog_slider .blog_slider_simple_info a {
    text-decoration: underline;
}

.pagination ul li.prev a, .pagination ul li.prev a:hover,
.pagination ul li.next a, .pagination ul li.next a:hover {
    border-color: transparent;
    background-color: transparent;
    font-size: 14px;
}

.pagination ul li span, .pagination ul li a:hover {
    border-color: #D1AF78;
}

.pagination ul li span, .pagination ul li a {
    font-family: 'Lato', sans-serif;
}

@media only screen and (max-width: 1000px) {
    .fixed_top_header .side_menu_button {
        border-right: none;
        border-left: none;
    }
}

.fixed_top_header .mobile_menu_button span {
    font-size: 16px;
}

.cf7_custom_style_1 input.wpcf7-form-control.wpcf7-text, .cf7_custom_style_1 textarea.wpcf7-form-control.wpcf7-textarea {
    text-align: center;
}

div.wpcf7 img.ajax-loader {
    display: block;
    margin: 0 auto;
}

aside .widget > h5 {
    background-color: #222;
    padding: 12px 5px;
    margin-bottom: 39px;
}

aside .widget .latest_post_holder.image_in_box .latest_post_text .latest_post_title {
    font-family: 'Playfair Display', sans-serif;
    text-transform: none;
}

aside .widget.posts_holder li {
    font-family: 'Lato', sans-serif;
    font-size: 10px;
    letter-spacing: 2px;
}

.latest_post_holder.image_in_box .latest_post_text .latest_post_title {
    margin: -3px 0 7px;
}

.qode-instagram-feed li {
    margin: 0px 0px 2px 0px !important;
    padding: 0px 1px !important;
}

.qode-instagram-feed {
    margin-left: -1px;
    margin-right: -2px;
}

.container aside {
    text-align: center;
}

aside .widget ul {
    text-align: left;
}

aside .widget.posts_holder li {
    padding: 4px 0;
}

.q_dropcap {
    font-family: 'Playfair Display', serif;
}

.blog_holder.blog_single article .post_info {
    text-decoration: underline;
}

.comment_holder .comment {
    padding: 30px 25px 49px;
    border-bottom: 1px #e0e0e0 solid;
}

.comments .comment .text .comment_date {
    font-size: 12px;
}

.comments .comment .text .text_holder {
    margin: 13px 0 0;
}

.comment_holder .comment .text .comment-reply-link, .comment_holder .comment .text .replay {
    font-size: 11px;
    letter-spacing: 1px;
}

div.comment_form {
    margin: 25px 0 75px;
}

@media only screen and (max-width: 600px) {
    h2 a {
        font-size: 25px;
        line-height: 31px;
    }
    .fb-list__item {
        width: 90%; /* 4 items per row */
        padding-bottom: 10px;
        padding-left: 10px;
    }
}

@media only screen and (max-width: 1000px) {
    .comment_holder .comment {
        padding: 27px 0 40px;
    }
}

/*HOMEPAGE*/
.fb-list {
    overflow: hidden; /* just clearing floats */
    list-style: none;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.fb-list__item {
    width: 47%; /* 4 items per row */
    padding-bottom: 20px;
    padding-left: 20px;
}

.fb-list__item .published {
    font-size: 12px;
}

.fb-list__item img {
    width: 100%;
}