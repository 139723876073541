    /* Webkit */
    ::selection {
    background: #ffb300;
    }
    /* Gecko/Mozilla */
    ::-moz-selection {
    background: #ffb300;
    }


    h1 a:hover,
    .box_image_holder .box_icon .fa-stack i.fa-stack-base,
    .q_percentage_with_icon,
    .filter_holder ul li.active span,
    .filter_holder ul li:hover span,
    .q_tabs .tabs-nav li.active a:hover,
    .q_tabs .tabs-nav li a:hover,
    .q_accordion_holder.accordion .ui-accordion-header:hover,
    .q_accordion_holder.accordion.with_icon .ui-accordion-header i,
    .testimonials .testimonial_text_inner p.testimonial_author span.author_company,
    .testimonial_content_inner .testimonial_author .company_position,
    .q_icon_with_title.center .icon_holder .font_awsome_icon i:hover,
    .q_box_holder.with_icon .box_holder_icon_inner .fa-stack i.fa-stack-base,
    .q_icon_with_title.boxed .icon_holder .fa-stack,
    .q_progress_bars_icons_inner .bar.active i.fa-circle,
    .q_list.number ul>li:before,
    .q_social_icon_holder:hover .simple_social,
    .social_share_dropdown ul li :hover i,
    .social_share_list_holder ul li i:hover,
	.blog_holder.blog_masonry_date_in_image .social_share_list_holder ul li i:hover,
    .latest_post_inner .post_infos a:hover,
    .q_masonry_blog article .q_masonry_blog_post_info a:hover,
    .blog_holder article:not(.format-quote):not(.format-link) .post_info a:hover,
    .latest_post_inner .post_comments:hover i,
    .blog_holder article .post_description a:hover,
    .blog_holder article .post_description .post_comments:hover,
    .blog_like a:hover i,
    .blog_like a.liked i,
	.latest_post .blog_like a:hover span,
    article:not(.format-quote):not(.format-link) .blog_like a:hover span,
    .comment_holder .comment .text .replay,
    .comment_holder .comment .text .comment-reply-link,
    .header-widget.widget_nav_menu ul.menu li a:hover,
    aside .widget a:hover,
    aside .widget.posts_holder li:hover,
    .q_steps_holder .circle_small:hover span,
    .q_steps_holder .circle_small:hover .step_title,
    .header_top #lang_sel > ul > li > a:hover,
    .header_top #lang_sel_click > ul > li> a:hover,
    .header_top #lang_sel_list ul li a.lang_sel_sel,
    .header_top #lang_sel_list ul li a:hover,
    aside .widget #lang_sel a.lang_sel_sel:hover,
    aside .widget #lang_sel_click a.lang_sel_sel:hover,
    aside .widget #lang_sel ul ul a:hover,
    aside .widget #lang_sel_click ul ul a:hover,
    aside .widget #lang_sel_list li a.lang_sel_sel,
    aside .widget #lang_sel_list li a:hover,
    .service_table_inner li.service_table_title_holder i,
    .latest_post_two_holder .latest_post_two_text a:hover,
        .q_team .q_team_social_holder .q_social_icon_holder:hover .simple_social,
	.portfolio_template_8 .portfolio_detail .info .category,
	.portfolio_navigation.navigation_title .post_info span.categories,
	.qode_portfolio_related .projects_holder article .portfolio_description .project_category,
	.blog_compound article .post_content .blog_like a:hover, 
	.blog_compound article .post_content .blog_like a:hover span, 
	.blog_compound article .post_content .blog_share a:hover, 
	.blog_compound article .post_content .blog_share a:hover span, 
	.blog_compound article .post_content .post_comments:hover, 
	.blog_compound article .post_content .post_comments:hover span,
	.blog_holder.blog_pinterest article.format-link .post_info a:hover, 
	.blog_holder.blog_pinterest article.format-quote .post_info a:hover,
	.blog_compound .post_title .category a,
	.blog_compound .post_title .category span.date,
	.q_price_table.qode_pricing_table_advanced .qode_pt_subtitle,
	.q_price_table.qode_pricing_table_advanced .qode_pt_additional_info .qode_pt_icon,
	.q_price_table.qode_pricing_table_advanced .price_table_inner .value
	{
        color: #ffb300 !important;
    }
	h2 a:hover,
	h3 a:hover,
	h4 a:hover,
	h5 a:hover,
	h6 a:hover,
	a:hover,
	p a:hover,
	.portfolio_share .social_share_holder a:hover,
	.breadcrumb .current,
	.breadcrumb a:hover,
	.q_icon_with_title .icon_with_title_link,
	.q_counter_holder span.counter,
	.q_font_awsome_icon i,
    .q_font_awsome_icon span,
	.q_dropcap,
	.q_counter_holder span.counter,
	nav.mobile_menu ul li a:hover,
	nav.mobile_menu ul li.active > a,
	.q_progress_bars_icons_inner.square .bar.active i,
	.q_progress_bars_icons_inner.circle .bar.active i,
	.q_progress_bars_icons_inner.normal .bar.active i,
	.q_font_awsome_icon_stack .fa-circle,
	.footer_top .q_social_icon_holder:hover .simple_social,
	.more_facts_button:hover,
	.box_holder_icon .fa-stack i,
	.blog_large_image_simple .minimalist_date,
	nav.content_menu ul li.active:hover i,
	nav.content_menu ul li:hover i,
	nav.content_menu ul li.active:hover a,
	nav.content_menu ul li:hover a,
	.vc_grid-container .vc_grid-filter.vc_grid-filter-color-grey > .vc_grid-filter-item:hover span,
	.vc_grid-container .vc_grid-filter.vc_grid-filter-color-grey > .vc_grid-filter-item.vc_active span,
    .q_font_awsome_icon i:hover,
    .q_font_awsome_icon span:hover,
    .fullscreen_search_holder .search_submit:hover
	{

		color: #ffb300;
	}

    .box_image_with_border:hover,
    .qbutton:hover,
	.vc_grid-container .vc_row.vc_grid .vc_grid-item .vc_btn:hover,
	.vc_grid-container .vc_row.vc_grid .vc_pageable-load-more-btn .vc_btn:hover,
    .load_more a:hover,
    .blog_load_more_button a:hover,
    #submit_comment:hover,
    .drop_down .wide .second ul li .qbutton:hover,
    .drop_down .wide .second ul li ul li .qbutton:hover,
    .qbutton.white:hover,
    .qbutton.green,
    .portfolio_slides .hover_feature_holder_inner .qbutton:hover,
    .testimonials_holder.light .flex-direction-nav a:hover,
    .q_progress_bars_icons_inner.square .bar.active .bar_noactive,
    .q_progress_bars_icons_inner.square .bar.active .bar_active,
    .q_progress_bars_icons_inner.circle .bar.active .bar_noactive,
    .q_progress_bars_icons_inner.circle .bar.active .bar_active,
    .widget.widget_search form.form_focus,
    .q_steps_holder .circle_small_wrapper,
    .animated_icon_inner span.animated_icon_back i,
	.blog_holder article.format-link .post_text:hover .post_text_inner,
	.blog_holder article.format-quote .post_text:hover .post_text_inner,
        input.wpcf7-form-control.wpcf7-submit:not([disabled]):hover,
    .portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover .icons_holder a:hover {
        border-color: #ffb300    }

    .q_icon_list i,
    .q_progress_bar .progress_content,
    .q_progress_bars_vertical .progress_content_outer .progress_content,
    .qbutton:hover,
	.vc_grid-container .vc_row.vc_grid .vc_grid-item .vc_btn:hover,
	.vc_grid-container .vc_row.vc_grid .vc_pageable-load-more-btn .vc_btn:hover,
	.post-password-form input[type='submit']:hover,
    .load_more a:hover,
    .blog_load_more_button a:hover,
    #submit_comment:hover,
    .drop_down .wide .second ul li .qbutton:hover,
    .drop_down .wide .second ul li ul li .qbutton:hover,
    .qbutton.white:hover,
    .qbutton.green,
    .call_to_action,
    .highlight,
    .testimonials_holder.light .flex-direction-nav a:hover,
    .q_dropcap.circle,
    .q_dropcap.square,
    .q_message,
    .q_price_table.active .active_text,
    .q_icon_with_title.boxed .icon_holder .fa-stack,
    .q_font_awsome_icon_square,
    .q_icon_with_title.square .icon_holder .fa-stack:hover,
    .box_holder_icon_inner.square .fa-stack:hover,
    .box_holder_icon_inner.circle .fa-stack:hover,
    .circle .icon_holder .fa-stack:hover,
    .q_list.number.circle_number ul>li:before,
    .q_social_icon_holder.circle_social .fa-stack:hover,
    .social_share_dropdown ul li.share_title,
    .latest_post_holder .latest_post_date .post_publish_day,
    .q_masonry_blog article.format-link:hover,
    .q_masonry_blog article.format-quote:hover,
    #wp-calendar td#today,
    .vc_text_separator.full div,
    .mejs-controls .mejs-time-rail .mejs-time-current,
    .mejs-controls .mejs-time-rail .mejs-time-handle,
    .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current,
    .q_pie_graf_legend ul li .color_holder,
    .q_line_graf_legend ul li .color_holder,
    .q_team .q_team_text_inner .separator,
    .circle_item .circle:hover,
    .qode_call_to_action.container,
    .qode_carousels .flex-control-paging li a.flex-active,
    .animated_icon_inner span.animated_icon_back i,
    	.q_circles_holder .q_circle_inner2:hover,
    input.wpcf7-form-control.wpcf7-submit:not([disabled]):hover,
    .portfolio_main_holder .item_holder.subtle_vertical_hover .icons_holder a,
    .portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover .icons_holder a:hover,
    .portfolio_main_holder .item_holder.image_text_zoom_hover .icons_holder a,
    .portfolio_main_holder .item_holder.slow_zoom .icons_holder a,
	.qode_video_box .qode_video_image:hover .qode_video_box_button,
	.blog_holder.masonry_gallery article.format-link:hover,
	.blog_holder.masonry_gallery article.format-quote:hover,
	.blog_holder.blog_chequered article.format-link:hover,
	.blog_holder.blog_chequered article.format-quote:hover
	{
        background-color: #ffb300;
    }


    .q_circles_holder .q_circle_inner2:hover,
	.blog_holder article.format-link .post_text:hover .post_text_inner,
	.blog_holder article.format-quote .post_text:hover .post_text_inner {
        background-color: #ffb300 !important;
        border-color: #ffb300 !important;
    }


    .ajax_loader .pulse,
    .ajax_loader .double_pulse .double-bounce1, .ajax_loader .double_pulse .double-bounce2,
    .ajax_loader .cube,
    .ajax_loader .rotating_cubes .cube1, .ajax_loader .rotating_cubes .cube2,
    .ajax_loader .stripes > div,
    .ajax_loader .wave > div,
    .ajax_loader .two_rotating_circles .dot1, .ajax_loader .two_rotating_circles .dot2,
    .ajax_loader .five_rotating_circles .container1 > div, .ajax_loader .five_rotating_circles .container2 > div, .ajax_loader .five_rotating_circles .container3 > div{
    background-color: #222222;
    }

    body{
    	    	    	font-family: 'Karla', sans-serif;
    	    	 color: #7f7f7f;     	 font-size: 16px;     	font-weight: 400;    }
            body,
		.wrapper,
        .content,
        .full_width,
        .overlapping_content .content > .container,
		.more_facts_holder,
		.comment_holder .comment #respond textarea,
		.comment_holder .comment #respond input[type='text'],
		.content .container
		{
        	background-color:#ffffff;
        }
		.angled-section polygon{
			fill: #ffffff;
		}
    



	@media only screen and (min-width: 1000px) {
		.content .container .container_inner.default_template_holder,
		.content .container .container_inner.page_container_inner
		{
			padding-top:0px;
		}
	}








	.fixed_top_header .header_top{
		height: 40px;
	}
	.fixed_top_header.has_top .bottom_header{
		padding-top:  40px;
	}
	
	.fixed_top_header  nav.main_menu > ul > li > a{
		line-height: 40px;
    }
	.fixed_top_header .side_menu_button,
	.fixed_top_header .shopping_cart_inner,
	.fixed_top_header .header_bottom_right_widget_holder{
		height: 40px;
	}
	


								.content{
				margin-top: -313px;
			}
			
	.content.content_top_margin{
		margin-top: 263px !important;
	}




        header.fixed_hiding .q_logo a,
        header.fixed_hiding .q_logo{
            max-height: 75.5px;
        }

    @media only screen and (max-width: 1000px){
        .q_logo a,.q_logo img{
            height: 95px !important;
        }
    }   

    @media only screen and (max-width: 480px){
        .q_logo a,.q_logo img{
            height: 85px !important;
        }
    }   


    .touch section.parallax_section_holder{
		height: auto !important;
		min-height: 300px;  
		background-position: center top !important;  
		background-attachment: scroll;
        background-size: cover;
	}



nav.main_menu > ul > li > a{
	 color: #222222; 		font-family: 'Karla', sans-serif;
		 font-size: 16px;     	 font-style: normal; 	 font-weight: 500; 	 letter-spacing: 2px; 	 text-transform: none; }


	nav.main_menu > ul > li > a{
		padding: 0 15px;
	}
	header.transparent .drop_down .second:not(.right){
		left: 14px;
	}

nav.main_menu ul li:hover a {
	 color: #ffb300; }

		nav.main_menu ul li.active a {
			color: #ffb300		}
	




.drop_down .second .inner > ul > li > a,
.drop_down .second .inner > ul > li > h3,
.drop_down .wide .second .inner > ul > li > h3,
.drop_down .wide .second .inner > ul > li > a,
.drop_down .wide .second ul li ul li.menu-item-has-children > a,
.drop_down .wide .second .inner ul li.sub ul li.menu-item-has-children > a,
.drop_down .wide .second .inner > ul li.sub .flexslider ul li  h5 a,
.drop_down .wide .second .inner > ul li .flexslider ul li  h5 a,
.drop_down .wide .second .inner > ul li.sub .flexslider ul li  h5,
.drop_down .wide .second .inner > ul li .flexslider ul li  h5,
.header_top #lang_sel ul li ul li a {
			font-family: 'Karla', sans-serif !important;
		 font-size: 13px; 		 font-style: normal;  		 text-transform: none;  	}






nav.mobile_menu ul li a,
nav.mobile_menu ul li h3{
	 color: #222222; 		font-family: 'Lato', sans-serif;
		 font-size: 10px; 		 font-style: normal; 	 font-weight: 400; 		letter-spacing: 2px;
			text-transform: uppercase;
	}


	nav.mobile_menu ul li span.mobile_arrow i, nav.mobile_menu ul li span.mobile_arrow i {
	    color: #222222;
	}

nav.mobile_menu ul li a:hover,
nav.mobile_menu ul li.active > a,
nav.mobile_menu ul li.current-menu-item > a{
	color: #d1af78;
}
	nav.mobile_menu ul li,
	nav.mobile_menu ul li,
	nav.mobile_menu ul li ul li,
    nav.mobile_menu ul li.open_sub > ul{
		border-color: #f1f1f1;
	}

	#respond textarea,
	#respond input[type='text'],
	.contact_form input[type='text'],
	.contact_form  textarea,
	.comment_holder #respond textarea,
	.comment_holder #respond input[type='text'],
	input.wpcf7-form-control.wpcf7-text,
	input.wpcf7-form-control.wpcf7-number,
	input.wpcf7-form-control.wpcf7-date,
	textarea.wpcf7-form-control.wpcf7-textarea,
	select.wpcf7-form-control.wpcf7-select,
	input.wpcf7-form-control.wpcf7-quiz,
	.post-password-form input[type='password']
	{
	background-color: #ffffff; 	border: 1px solid #cccccc; 	color:#7f7f7f; 	}






h2,
.h2,
h2 a{
	color: #222222; 			font-family: 'Karla', sans-serif;
		font-size: 25px; 		font-style: normal; 	font-weight: 400;     letter-spacing: 0px; 	text-transform: none; }
h5,
.h5,
h5 a,
.q_icon_with_title .icon_text_holder h5.icon_title{
			font-size: 16px; 		font-style: normal; 	font-weight: 600;     letter-spacing: 1px; 	}
h6,
.h6,
h6 a {
	color: #d1af78; 		font-family: 'Lato', sans-serif;
		font-size: 10px; 	line-height: 20px; 	font-style: normal;  	font-weight: 400; 	letter-spacing: 2px; 	text-transform: uppercase; }

	.blog_holder article.format-quote .post_text .post_title p,
	.blog_holder article.format-link .post_text .post_title p,
	.blog_holder article.format-quote .post_text .quote_author,
	blockquote h5
	{
									font-family: 'Playfair Display', sans-serif;
		}
    p{
    	color: #7f7f7f;    	    		font-family: 'Karla', sans-serif;
    	    	font-size: 16px;    	line-height: 22px;    	font-style: normal;    	font-weight: 400;    	    	    }
    .filter_holder ul li span,
    blockquote h5,
    .q_social_icon_holder .simple_social,
    .header-widget.widget_nav_menu ul.menu li a,
    .side_menu a,
    .side_menu li,
    .side_menu span,
    .side_menu p,
    .side_menu .widget.widget_rss li a.rsswidget,
    .side_menu #wp-calendar caption,
    .side_menu #wp-calendar th, 
    .side_menu #wp-calendar td,
    aside .widget #lang_sel_list li a,
    aside .widget #lang_sel li a,
    aside .widget #lang_sel_click li a,
    section.side_menu #lang_sel_list li a,
    section.side_menu #lang_sel li a,
    section.side_menu #lang_sel_click li a,
    footer #lang_sel_list li a,
    footer #lang_sel li a,
    footer #lang_sel_click li a,
    footer #lang_sel_list.lang_sel_list_horizontal a,
    footer #lang_sel_list.lang_sel_list_vertical a,
    .side_menu #lang_sel_list.lang_sel_list_horizontal a,
    .side_menu #lang_sel_list.lang_sel_list_vertical a,
    #lang_sel_footer a{
    	color: #7f7f7f;    }
    .header_top #lang_sel > ul > li > a, 
    .header_top #lang_sel_click > ul > li> a,
    footer #lang_sel ul li a,
    footer #lang_sel ul ul a,
    footer #lang_sel_click ul li a,
    footer #lang_sel_click ul ul a,
    footer #lang_sel_click ul ul a span,
    section.side_menu #lang_sel ul li a,
    section.side_menu #lang_sel ul ul a,
    section.side_menu #lang_sel ul ul a:visited,
    section.side_menu #lang_sel_click > ul > li > a,
    section.side_menu #lang_sel_click ul ul a,
    section.side_menu #lang_sel_click ul ul a:visited{
    	color: #7f7f7f !important;    }
    	blockquote h5{
		color: #222222;
	}







.qbutton,
.qbutton.medium,
#submit_comment,
.load_more a,
.blog_load_more_button a,
.post-password-form input[type='submit'],
input.wpcf7-form-control.wpcf7-submit,
input.wpcf7-form-control.wpcf7-submit:not([disabled]),
.woocommerce table.cart td.actions input[type="submit"],
.woocommerce input#place_order,
.woocommerce-page input[type="submit"],
.woocommerce .button
	{
	color: #222222; 		font-family: 'Lato', sans-serif;
	
    	border-color: #d1af78; 
		font-size: 12px; 		line-height: 40px; 		height: 40px; 		font-style: normal; 		font-weight: 400; 		background-color: #ffffff; 		border-radius: 0px; 		-moz-border-radius: 0px; 		-webkit-border-radius: 0px; 		letter-spacing: 2px; 		text-transform: uppercase; 	 border-width: 1px;		padding-left: 20px; padding-right: 20px; }

	.qbutton:hover,
	.qbutton.medium:hover,
	#submit_comment:hover,
	.load_more a:hover,
	.blog_load_more_button a:hover,
	.post-password-form input[type='submit']:hover,
	input.wpcf7-form-control.wpcf7-submit:not([disabled]):hover,
    .woocommerce table.cart td.actions input[type="submit"]:hover,
    .woocommerce input#place_order:hover,
    .woocommerce-page input[type="submit"]:hover,
	.woocommerce .button:hover
	{
	 color: #ffffff; 
	 border-color: #d1af78	}

	.qbutton:hover,
	#submit_comment:hover,
	.load_more a:hover,
	.blog_load_more_button a:hover,
	.post-password-form input[type='submit']:hover,
	input.wpcf7-form-control.wpcf7-submit:not([disabled]):hover,
    .woocommerce table.cart td.actions input[type="submit"]:hover,
    .woocommerce input#place_order:hover,
    .woocommerce-page input[type="submit"]:hover,
	.woocommerce .button:hover
	{
		 background-color: #d1af78; 			}
	.qbutton.small{

		font-size: 10px; 		line-height: 36px; 		height: 36px; 		font-weight: 400; 		padding-left: 20px; 		padding-right: 20px; 			
	}






.google_map{
	height: 750px;
}


	.footer_top_holder,	footer #lang_sel > ul > li > a,	footer #lang_sel_click > ul > li > a{
		background-color: #ffffff;
	}
	footer #lang_sel ul ul a,footer #lang_sel_click ul ul a,footer #lang_sel ul ul a:visited,footer #lang_sel_click ul ul a:visited{
		background-color: #ffffff !important;
	}

    .footer_top,
    .footer_top.footer_top_full{
     padding-top: 0px 
 }

.footer_top .column_inner > div h2,
.footer_top .column_inner > div h3,
.footer_top .column_inner > div h4,
.footer_top .column_inner > div h5,
.footer_top .column_inner > div h6 {
	color:#ffffff;
}
	.footer_top,
	.footer_top p,
    .footer_top span,
    .footer_top li,
    .footer_top .textwidget,
    .footer_top .widget_recent_entries>ul>li>span {
		color: #bababa;
	}


	.footer_bottom_holder, #lang_sel_footer{
		background-color:#828282;
	}



	.footer_bottom_holder{
	padding-bottom: 27px	}
	.footer_bottom{
		padding-top: 20px;
	}
	.footer_bottom ul.menu li{
		margin-right: 29px;
	}

	.footer_bottom ul.menu li:last-child{
		margin-right: 0;
	}







    .footer_bottom_holder,
    .footer_bottom,
    .footer_bottom p,
    .footer_bottom_holder p,
    .footer_bottom span:not(.q_social_icon_holder):not(.fa-stack):not(.qode_icon_font_elegant){
	font-family: "PT Sans", sans-serif;font-size: 14px;letter-spacing: 0px;font-weight: 400;text-transform: none;font-style: normal	}

    .footer_bottom_holder a,
    .footer_bottom_holder ul li a{
font-family: "Lato", sans-serif;font-size: 10px;letter-spacing: 2px;font-weight: 400;text-transform: uppercase;font-style: normal	}

.footer_bottom, .footer_bottom span, .footer_bottom p, .footer_bottom p a, .footer_bottom a, #lang_sel_footer ul li a,
footer #lang_sel > ul > li > a,
footer #lang_sel_click > ul > li > a,
footer #lang_sel a.lang_sel_sel,
footer #lang_sel_click a.lang_sel_sel,
footer #lang_sel ul ul a,
footer #lang_sel_click ul ul a,
footer #lang_sel ul ul a:visited,
footer #lang_sel_click ul ul a:visited,
footer #lang_sel_list.lang_sel_list_horizontal a,
footer #lang_sel_list.lang_sel_list_vertical a,
#lang_sel_footer a,
.footer_bottom ul li a {
	color:#ffffff;
}

	.footer_bottom p a:hover, .footer_bottom a:hover, #lang_sel_footer ul li a:hover,
	footer #lang_sel > ul > li > a:hover,
	footer #lang_sel_click > ul > li > a:hover,
	footer #lang_sel a.lang_sel_sel:hover,
	footer #lang_sel_click a.lang_sel_sel:hover,
	footer #lang_sel ul ul a:hover,
	footer #lang_sel_click ul ul a:hover,
	footer #lang_sel ul ul a:hover,
	footer #lang_sel_click ul ul a:hover,
	footer #lang_sel_list.lang_sel_list_horizontal a:hover,
	footer #lang_sel_list.lang_sel_list_vertical a:hover,
	#lang_sel_footer a:hover,
	.footer_bottom ul li a:hover {
	    color: #6cbe99;
	}










	.blog_holder.blog_large_image article .post_text h2 .date,
	.blog_holder.blog_single article .post_text h2 .date
	{
	color:#d1af78;
	}

	.blog_holder.blog_large_image article:not(.format-quote):not(.format-link) .post_info,
	.blog_holder.blog_single article:not(.format-quote):not(.format-link) .post_info{
	font-size: 10px;line-height: 26px;font-style: normal;font-weight: 400;letter-spacing: 2px;text-transform: uppercase;color: #d1af78;	}
	.blog_holder.blog_large_image article:not(.format-quote):not(.format-link) .post_info a,
	.blog_holder.blog_single article:not(.format-quote):not(.format-link) .post_info a{
	color:#d1af78;
	}
	.blog_holder.blog_large_image article:not(.format-quote):not(.format-link) .post_text_inner .post_info a:hover,
	.blog_holder.blog_large_image article:not(.format-quote):not(.format-link) .post_text_inner .post_info a:hover span,
	.blog_holder.blog_single article:not(.format-quote):not(.format-link) .post_text_inner .post_info a:hover,
	.blog_holder.blog_single article:not(.format-quote):not(.format-link) .post_text_inner .post_info a:hover span{
	color:#d1af78 !important;
	}














/*Blog Masonry Gallery - end */




























	.blog_holder.blog_single article .post_text .post_text_inner{
		padding-top: 50px;
	}
	.blog_holder.blog_single article h2{
		margin-bottom: 11px;
	}
	.blog_holder.blog_single article .post_info{
		margin-bottom: 17px;
	}
    .side_menu_button > a,
    .mobile_menu_button span,
	.fixed_top_header .side_menu_button > a,
    .fixed_top_header .popup_menu .line,
    .fixed_top_header .mobile_menu_button span{ color: #222222;font-size: 12px; }

    .popup_menu .line,
    .popup_menu .line:after, .popup_menu .line:before{
        background-color: #222222;
    }
    .side_menu_button > a:hover,
    .mobile_menu_button span:hover,
    .popup_menu:hover .line,
    .popup_menu:hover .line:after,
    .popup_menu:hover .line:before{ color: #d1af78; }

    .popup_menu:hover .line,
    .popup_menu:hover .line:after, .popup_menu:hover .line:before{
        background-color: #d1af78;
    }
  
 
 .vertical_menu_float .menu-item .second{
	left: calc(100% + 30px); /*because of the padding*/
}

.vertical_menu_hidden aside.vertical_menu_area .vertical_menu_float .menu-item .second {
	left: calc(100% + 40px);
}

 
 
 




	.vertical_menu_area{
		text-align:left;
	}
	
















	.header_top .q_social_icon_holder .simple_social:not(.qode_icon_font_elegant),
	.header_top .header-widget,
	.header_top .header-widget.widget_nav_menu ul.menu>li>a,
	.header_top .header-widget p,
	.header_top .header-widget a,
	.header_top .header-widget span:not(.qode_icon_font_elegant) {
	     font-size: 13px;	}



    .portfolio_main_holder .item_holder.thin_plus_only .thin_plus_only_icon {
            }
	.cf7_custom_style_1 textarea.wpcf7-form-control.wpcf7-textarea{
	height: 105px;
	}
		.qode_search_form i,
		.qode_search_form .container input {
			color: #222222;		}
		


	.qode_search_form_2 input[type="text"],
	.qode_search_form_2 input[type="text"]:focus,
	.qode_search_form_3 input[type="text"],
	.qode_search_form_3 input[type="text"]:focus,
	.fullscreen_search_holder .search_field,
	.fullscreen_search_holder .search_field:focus,
	.qode_search_form input, 
	.qode_search_form input:focus{
		font-family: Lato, sans-serif;font-size: 10px !important;letter-spacing: 2px;font-weight: 400;font-style: normal;text-transform: uppercase;color: #222222    }

	.qode_search_form  input[type="text"]::-webkit-input-placeholder,
	.qode_search_form_2 input[type="text"]::-webkit-input-placeholder,
	.qode_search_form_3 input[type="text"]::-webkit-input-placeholder,
	.fullscreen_search_holder .search_field::-webkit-input-placeholder{
		color: #222222;
    }

	.qode_search_form  input[type="text"]::-moz-placeholder,
	.qode_search_form_2 input[type="text"]::-moz-placeholder,
	.qode_search_form_3 input[type="text"]::-moz-placeholder,
	.fullscreen_search_holder .search_field::-moz-placeholder{
		color: #222222;
    }

	.qode_search_form  input[type="text"]::-moz-placeholder,
	.qode_search_form_2 input[type="text"]::-moz-placeholder,
	.qode_search_form_3 input[type="text"]::-moz-placeholder,
	.fullscreen_search_holder .search_field::-moz-placeholder{
		color: #222222;
    }


	.side_menu_button .search_covers_header,
	.side_menu_button .search_slides_from_header_bottom,
	.side_menu_button .fullscreen_search,
	.side_menu_button .search_slides_from_window_top{
		font-size: 12px !important;
    }







    .fullscreen_search_holder .close_container a,
	.fullscreen_search_close,
	.qode_search_form_3 .qode_search_close a,
	.qode_search_form .qode_search_close i,
    .qode_search_form .qode_search_close .qode_icon_in_search{
		color: #222222;
    }

    .fullscreen_search_holder .close_container a:hover,
	.fullscreen_search_close:hover,
	.qode_search_form_3 .qode_search_close a:hover,
	.qode_search_form .qode_search_close i:hover,
    .qode_search_form .qode_search_close a:hover .qode_icon_in_search{
		color: #d1af78;
    }

    .fullscreen_search_holder .close_container a,
	.fullscreen_search_close,
	.qode_search_form_3 .qode_search_close,
	.qode_search_form  .qode_search_close i,
    .qode_search_form .qode_search_close .qode_icon_in_search{
		font-size: 20px !important;
    }





































    #back_to_top:hover span i{
    	color: #ffffff;
	}

	#back_to_top:hover span{
		background-color: rgba(209,175,120,1);border-color: rgba(209,175,120,1)	}












		.blog_slider .blog_slider_simple_title a{
		font-family: Playfair Display, sans-serif;font-size: 32px;line-height: 32px;letter-spacing: 0px;font-weight: 400;font-style: normal;text-transform: none;color: #222222		}


	.blog_slider .blog_slider_simple_title a:hover{
		color: #d1af78;
	}

	.blog_slider .blog_slider_simple_title{
		line-height: 32px;
	}

		.blog_slider .blog_slider_simple_info{
		font-family: Lato, sans-serif;font-size: 10px;line-height: 20px;letter-spacing: 2px;font-weight: 400;font-style: normal;text-transform: uppercase;color: #d1af78		}


	.blog_slider .blog_slider_simple_info a{
		color: #d1af78;
	}


	.blog_slider .blog_slider_simple_info a:hover{
		color: #d1af78;
	}


	.blog_slider .blog_slider_simple_title{
		margin-bottom: 18px;
	}

	.blog_slider .blog_slider_simple_info{
		margin-bottom: 19px;
	}

	.blog_slider .blog_slider_simple_excerpt{
		margin-bottom: 25px;
	}

		.blog_slider_holder .blog_slider.simple_slider .blog_text_holder_inner2{
		background-color: rgba(255,255,255,1);border-color: rgba(255,255,255,1);padding-top: 47px;padding-right: 40px;padding-bottom: 54px;padding-left: 40px;width: 38%		}


		.pagination ul li a{
		border-color: #e5e5e5;color: #222222		}
			.pagination ul li a:hover, .pagination ul li span{
		border-color: #ffffff;background-color: #ffffff;color: #222222		}
			.pagination ul li a, .pagination ul li span{
		width: 36px;height: 36px;line-height: 36px;border-width: 1px;border-radius: 0px;font-size: 10px;margin-right: 3px		}
		
	

	.caroufredsel-direction-nav a,
	.qode_image_gallery_no_space .controls a.prev-slide span,
	.qode_image_gallery_no_space .controls a.next-slide span,
	.portfolio_slider .caroufredsel-next,
	.portfolio_slider .caroufredsel-prev,
	.blog_slider .caroufredsel-next,
	.full_width .section_inner .blog_slider .caroufredsel-next,
	.blog_slider .caroufredsel-prev,
	.full_width .section_inner .blog_slider .caroufredsel-prev{
	background-color: rgba(255,255,255,0); border-color: rgba(255,255,255,0); border-width: 0px; border-style: solid	}
	.caroufredsel-direction-nav a:hover,
	.qode_image_gallery_no_space .controls a.prev-slide:hover span,
	.qode_image_gallery_no_space .controls a.next-slide:hover span,
	.portfolio_slider:hover .caroufredsel-direction-nav a.caroufredsel-next:hover,
	.portfolio_slider:hover .caroufredsel-direction-nav a.caroufredsel-prev:hover,
	.blog_slider:hover .caroufredsel-direction-nav a.caroufredsel-next:hover,
	.blog_slider:hover .caroufredsel-direction-nav a.caroufredsel-prev:hover{
	background-color: rgba(255,255,255,0); border-color: rgba(255,255,255,0)	}
	.caroufredsel-direction-nav a i,
	.qode_image_gallery_no_space .controls a.prev-slide i,
	.qode_image_gallery_no_space .controls a.next-slide i{
	font-size: 38px; color: rgba(255,255,255,1)	}
	.caroufredsel-direction-nav a:hover i,
	.qode_image_gallery_no_space .controls a.prev-slide:hover i,
	.qode_image_gallery_no_space .controls a.next-slide:hover i,
	.portfolio_slider:hover .caroufredsel-direction-nav a.caroufredsel-next:hover i,
	.portfolio_slider:hover .caroufredsel-direction-nav a.caroufredsel-prev:hover i,
	.blog_slider:hover .caroufredsel-direction-nav a.caroufredsel-next:hover i,
	.blog_slider:hover .caroufredsel-direction-nav a.caroufredsel-prev:hover i{
	color: rgba(209,175,120,1)	}
	.caroufredsel-direction-nav a.caroufredsel-prev,
	.qode_image_gallery_no_space .controls a.prev-slide span,
	.portfolio_slider .caroufredsel-prev,
	.blog_slider .caroufredsel-prev,
	.full_width .section_inner .blog_slider .caroufredsel-prev{
		left: 5px;
	}

	.qode_image_gallery_no_space .controls a.prev-slide span,
	.qode_image_gallery_no_space .controls a.next-slide span{
		margin-left: 0;
		margin-right: 0;
	}

	.qode_image_gallery_no_space .controls a.next-slide span{
		left: auto;
	}

	.qode_image_gallery_no_space .controls a.next-slide{
		right: 0;
	}

	.caroufredsel-direction-nav a.caroufredsel-next,
	.qode_image_gallery_no_space .controls a.next-slide span,
	.portfolio_slider .caroufredsel-next,
	.blog_slider .caroufredsel-next,
	.full_width .section_inner .blog_slider .caroufredsel-next{
		right: 5px;
	}


	.flex-direction-nav a,
	.flexslider .flex-prev,
	.portfolio_slider .flex-prev,
	.flexslider .flex-next,
	.portfolio_slider .flex-next,
	body div.pp_default a.pp_next:after,
	body div.pp_default a.pp_previous:after,
    body a.pp_next:after,
    body a.pp_previous:after,
	.wpb_gallery .wpb_wrapper .wpb_flexslider .flex-direction-nav a,
	.qode_content_slider .flex-direction-nav .flex-prev,
	.qode_content_slider .flex-direction-nav .flex-next{
	background-color: rgba(255,255,255,0); border-color: rgba(255,255,255,0); border-width: 1px; border-style: solid;
	transition: all 0.15s ease-in-out;
	}
	.flex-direction-nav a:hover,
	.flexslider .flex-prev:hover,
	.portfolio_slider .flex-prev:hover,
	.flexslider .flex-next:hover,
	.portfolio_slider .flex-next:hover,
	body div.pp_default a.pp_next:hover:after,
	body div.pp_default a.pp_previous:hover:after,
    body a.pp_next:hover:after,
    body a.pp_previous:hover:after,
	.flexslider:hover .flex-direction-nav a.flex-prev:hover,
	.flexslider:hover .flex-direction-nav a.flex-next:hover,
	.portfolio_slider:hover .flex-direction-nav a.flex-prev:hover,
	.portfolio_slider:hover .flex-direction-nav a.flex-next:hover,
	.wpb_gallery .wpb_flexslider .flex-direction-nav a:hover,
	.qode_content_slider .flex-direction-nav .flex-prev:hover,
	.qode_content_slider .flex-direction-nav .flex-next:hover{
	background-color: rgba(255,255,255,0); border-color: rgba(255,255,255,0)	}
	.flex-direction-nav a i,
    body a.pp_next:after,
    body a.pp_previous:after,
	body div.pp_default a.pp_next:after,
	body div.pp_default a.pp_previous:after{
	font-size: 45px; color: rgba(255,255,255,1);
	transition: all 0.15s ease-in-out;
	}
	.flex-direction-nav a:hover i,
	body div.pp_default a.pp_next:hover:after,
	body div.pp_default a.pp_previous:hover:after,
    body a.pp_next:hover:after,
    body a.pp_previous:hover:after,
	.flexslider:hover .flex-direction-nav a.flex-prev:hover i,
	.flexslider:hover .flex-direction-nav a.flex-next:hover i,
	.portfolio_slider:hover .flex-direction-nav a.flex-prev:hover i,
	.portfolio_slider:hover .flex-direction-nav a.flex-next:hover i{
	color: rgba(209,175,120,1)	}
	.flexslider .flex-prev,
	.portfolio_slider .flex-prev,
    body a.pp_previous,
	body div.pp_default a.pp_previous:after{
		left: 9px;
	}

	.flexslider .flex-next,
	.portfolio_slider .flex-next,
    body a.pp_next,
	body div.pp_default a.pp_next:after{
		right: 9px;
	}


    .single_tags a,
    aside.sidebar .widget .tagcloud a,
    .widget .tagcloud a,
    aside.sidebar .widget:not(.woocommerce) .tagcloud a{
    font-size: 13px !important;letter-spacing: 0px    }

    .single_tags a{
    	margin: 0;
	}

    .masonry_gallery_item.square_big .masonry_gallery_item_inner .masonry_gallery_item_content{
    text-align: center;    }


    .masonry_gallery_item.square_small .masonry_gallery_item_inner .masonry_gallery_item_content{
    text-align: center;    }


    .masonry_gallery_item.rectangle_portrait .masonry_gallery_item_inner .masonry_gallery_item_content{
    text-align: center;    }


    .masonry_gallery_item.rectangle_landscape .masonry_gallery_item_inner .masonry_gallery_item_content{
    text-align: center;    }




	aside .widget h5:not(.latest_post_title){
	font-family: Lato;font-size: 10px;font-style: normal;font-weight: 400;letter-spacing: 2px;text-transform: uppercase;color: #ffffff;	}

    aside.sidebar .widget.widget_text,
    aside.sidebar .widget p,
    aside.sidebar .widget div:not(.star-rating) span:not(.qode_icon_element),
    aside.sidebar .widget li{
	font-size: 14px;line-height: 22px;	}

	aside.sidebar .widget:not(.qode_latest_posts_widget) a{
	font-family: Karla;font-size: 13px;line-height: 12px;font-style: normal;font-weight: 400;letter-spacing: 3px;text-transform: none;color: #222222;	}
	aside.sidebar .widget:not(.qode_latest_posts_widget) a:hover{
		color: #ffb300 !important;
	}


