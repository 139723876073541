@media only screen and (max-width: 1000px){
					.blog_slider_holder .blog_slider.simple_slider .blog_text_holder_inner2{
			width: 49.4%;
		}
				.content .container .container_inner.default_template_holder,
		.content .container .container_inner.page_container_inner {
			padding-top:0px;
		}
	}
@media only screen and (min-width: 480px) and (max-width: 768px){
	
	        section.parallax_section_holder{
			height: auto !important;
			min-height: 400px;
		}
		}
@media only screen and (max-width: 768px){
	
			h2 {
						line-height: 40px; 		    		}
	
	
	
	
	
	
	
			.blog_slider_holder .blog_slider.simple_slider .blog_text_holder_inner2 {
			width: 65%;
		}
	}
@media only screen and (max-width: 600px) {
	
			h2 {
			font-size: 25px; 			line-height: 31px; 		    		}
	
	
	
	
	
			body,
		p {
			font-size: 13px; 			line-height: 23px; 		    		}
	}
@media only screen and (max-width: 480px){

			section.parallax_section_holder {
			height: auto !important;
			min-height: 400px;
		}
	
		
	}